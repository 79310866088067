import {StepTrackingContractField} from './tracking/step-tracking-contract-field';

export class Tracking {
  constructor(
    public id: number,
    public actual_tracking_step: number,
    public steps_tracking: stepTracking[]
  ) {}
}
export class stepTracking {
  product_id: number;
  step_tracking_contract_field: StepTrackingContractField[];
  constructor(
    public id: number,
    public order: number,
    public title: string,
    public description: string,
    public btn_name_action: string,
    public jump_btw_steps: boolean,
    public actual_tracking_substep: number,
    public substeps_tracking: subStepTracking[],
    public actual_tracking_substep_id?: number
  ) {}
}

export class Notification {
  public html: string;
  public subject: string;
  public recipient: string;
  public recipient_2: string;
  public recipient_email: string;
  public recipient_email_2: string;
  public documents: Document[];
}
export class subStepTracking {
  public link: String;

  constructor(
    public id: number,
    public order: number,
    public title: string,
    public description: string,
    public btn_name_action: string,
    public responsible: string,
    public type: string,
    public sub_step_tracking_audit: SubStepTrackingAudit,
    public field_type: FieldType,
    public payment_step: number,
    public notification_type: NotificationType
  ) {}
}

export class SubStepTrackingAudit {
  public manual_trigger: boolean;
  public notification: Notification;
  constructor(
    public start_date?: Date,
    public end_date?: Date,
    public due_date?: Date,
    public user?: string,
    public document?: Document,
    public field_value?: string,
    public enable?: boolean,
    public field_value_content?: any,
    public enable_notifications?: any
  ) {
    this.manual_trigger = true;
  }
}

export class Document {
  constructor(
    public id?: number,
    public file?: string,
    public contract_id?: number,
    public service_contract_id?: number,
    public document_type_id?: number,
    public good_id?: number,
    public son_id?: number,
    public custom_document_type?: string,
    public validated?: boolean,
    public reviewed?: boolean,
    public content?: string,
    public constitution_person_id?: number,
    public visible_customer?: boolean,
    public is_static?: boolean
  ) {}
}

export class FieldType {
  constructor(
    public id?: number,
    public product_id?: number,
    public type?: string,
    public length?: number,
    public name?: string,
    public choices?: Choices[],
    public contractfieldtranslation?: Contractfieldtranslation,
    public contractfieldtranslation_input?: Contractfieldtranslation,
    public dependent_field?: FieldType,
    public dependent_field_value?: string,
    public dependent_field_id?: number,
    public show_detail?: boolean,
    public updated?: boolean,
    public new_field?: boolean,
    public choicesfield_set?: Choices[]
  ) {}
}

export class Contractfieldtranslation {
  public label: string;
  public order: number;
  public previous_form: boolean;
  public name: string;
  public language: string;

  constructor() {}
}

export class Choices {
  constructor(
    public id?: number,
    public pk?: string,
    public to_str?: string,
    public value?: string,
    public name?: string
  ) {}
}

export class NotificationType {
  constructor(public periodicity: number) {}
}

export class FormOrder {
  static readonly form_step_order: number = 2;
  static readonly marriage_situation_sub_step_order: number = 3;
  static readonly sons_sub_step_order: number = 4;
  static readonly goods_sub_step_order: number = 5;
  static readonly adjudications_sub_step_order: number = 6;
  static readonly pensions_sub_step_order: number = 7;
  static readonly pets_sub_step_order: number = 8;
  static readonly large_form_order: number = 1;
  static readonly large_form_sub_order: number = 2;
  static readonly large_form_order_buy_sell: number = 1;
  static readonly large_form_sub_order_buy_sell: number = 4;
  static readonly form_step_order_CR: number = 4;
  static readonly form_substep_order_datos_registrales: number = 1;
  static readonly form_substep_order_datos_registrales_limited_society: number = 3;
  static readonly form_substep_order_fiscal_data_limited_society: number = 2;
  static readonly form_step_order_limited_society_close_case: number = 2;
  static readonly form_substep_order_patents_and_trademarks: number = 3;
}

export class SLFormOrder {
  static readonly form_step_order: number = 1;
  static readonly form_sub_step_order: number = 3;
}
