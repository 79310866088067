<div class="column-selector show-on-mobile">
  <div
    class="column-selector-item"
    *ngFor="let tier of priceByTier"
    (click)="selectedTier = tier.name"
    [ngClass]="{ selected: selectedTier === tier.name }"
  >
    <span class="column-selector-item-name">{{ tier.name }}</span>
    <span class="column-selector-item-price">{{ tier.price }}€</span>
  </div>
</div>
<table>
  <thead class="hide-on-mobile">
    <tr class="header-row">
      <th></th>
      @if (priceByTier) {
      <th>
        <app-productcategory-agency-table-th
          [tier]="priceByTier[0]"
        ></app-productcategory-agency-table-th>
      </th>
      <th>
        <app-productcategory-agency-table-th
          [tier]="priceByTier[1]"
        ></app-productcategory-agency-table-th>
      </th>
      <th>
        <app-productcategory-agency-table-th
          [tier]="priceByTier[2]"
        ></app-productcategory-agency-table-th>
      </th>
      }
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let feature of getKeys()">
      <td class="feature-description">{{ feature }}</td>
      <td
        class="checkmark-container"
        [ngClass]="{ 'hide-on-mobile': selectedTier !== 'starter' }"
      >
        <img
          *ngIf="featuresByTier[feature].includes('starter')"
          src="assets/img/productcategory/checkmark.svg"
        />
      </td>
      <td
        class="checkmark-container"
        [ngClass]="{ 'hide-on-mobile': selectedTier !== 'pro' }"
      >
        <img
          *ngIf="featuresByTier[feature].includes('pro')"
          src="assets/img/productcategory/checkmark.svg"
        />
      </td>
      <td
        class="checkmark-container"
        [ngClass]="{ 'hide-on-mobile': selectedTier !== 'premium' }"
      >
        <img
          *ngIf="featuresByTier[feature].includes('premium')"
          src="assets/img/productcategory/checkmark.svg"
        />
      </td>
    </tr>
    @if (tableFooterType === 'autonomos') {
    <tr class="footerRow">
      <td class="first">
        <div class="description-footer">
          Descuento <b>para clientes de tu gestoría</b>
        </div>
      </td>
      <td
        class="first"
        [ngClass]="{ 'hide-on-mobile': selectedTier !== 'starter' }"
      >
        <div>5% DTO.</div>
      </td>
      <td
        class="first"
        [ngClass]="{ 'hide-on-mobile': selectedTier !== 'pro' }"
      >
        <div>10% DTO.</div>
      </td>
      <td
        class="first"
        [ngClass]="{ 'hide-on-mobile': selectedTier !== 'premium' }"
      >
        <div>15% DTO.</div>
      </td>
    </tr>
    <tr class="footerRow">
      <td>
        <div class="description-footer">Descuento <b>para empleados</b></div>
      </td>
      <td [ngClass]="{ 'hide-on-mobile': selectedTier !== 'starter' }">
        <div>10% DTO.</div>
      </td>
      <td [ngClass]="{ 'hide-on-mobile': selectedTier !== 'pro' }">
        <div>10% DTO.</div>
      </td>
      <td [ngClass]="{ 'hide-on-mobile': selectedTier !== 'premium' }">
        <div>10% DTO.</div>
      </td>
    </tr>
    } @if (tableFooterType === 'sociedades') {
    <tr class="footerRow">
      <td class="first">
        <div class="description-footer">
          Alta de autónomos <b>sin gestoría</b>
        </div>
      </td>
      <td class="first hide-on-mobile"><div class="hide">.</div></td>
      <td class="first"><div>40€</div></td>
      <td class="first hide-on-mobile"><div class="hide">.</div></td>
    </tr>
    <tr class="footerRow">
      <td>
        <div class="description-footer">
          Alta de autónomos <b>con gestoría</b>
        </div>
      </td>
      <td class="hide-on-mobile"><div class="hide">.</div></td>
      <td><div>Gratis</div></td>
      <td class="hide-on-mobile"><div class="hide">.</div></td>
    </tr>
    }
  </tbody>
</table>
<a class="empieza-ya show-on-mobile" (click)="openGenericProductModal()">
  <span>empieza ya</span>
  <div>
    <span>{{ selectedTier }}</span>
    <span class="price">{{ getPriceByTier(selectedTier) }}€</span>
  </div>
</a>
