import { Inject, PLATFORM_ID } from '@angular/core';
import { Card } from './card';
import { JuridicalPerson, PhysicalPerson } from './constitution';
import { InvoiceSubscriptions } from './invoice';
import { Address, Customer } from './user';
import { isPlatformBrowser } from '@angular/common';

export class Payment {
  public browser_data: BrowserData;
  public acs_data: Acsdata;
  public step: number;
  public card: Card;
  public is_verified: boolean;
  public error: string;
  public message: string;
  public id: string;
  public action: string;
  public status: string;
  public encoded_creq: string;
  public challenge_request_url: string;
  public payment_date: Date;
  public free_months: number;
  public wants_to_sign: boolean;
  public offer: boolean;
  public amount: number;

  constructor(private platformId: Object) {
    if (isPlatformBrowser(this.platformId)) {
      this.browser_data = new BrowserData();
    } else {
      this.browser_data = null;
    }
  }
}

export class Acsdata {
  public information_indicator: string;
  public method_url: string;
  public method_data: string;
  constructor() {}
}

export class BrowserData {
  public colorDepth: Number;
  public javaEnabled: Boolean;
  public browserLanguage: string;
  public screenHeight: Number;
  public screenWidth: Number;
  public userAgent: string;
  public browserTimezoneZoneOffset: Number;

  constructor() {
    this.colorDepth = screen.colorDepth; // 24
    this.javaEnabled = navigator.javaEnabled(); // true
    this.browserLanguage = navigator.language; // en_US
    this.screenHeight = screen.height; // 1080
    this.screenWidth = screen.width; // 1920
    this.userAgent = navigator.userAgent;
    var browserTime = new Date();
    this.browserTimezoneZoneOffset = browserTime.getTimezoneOffset(); // 0
  }
}

export class PaymentPrices {
  public payment_list: TransferPayment[];
  public contract_price: String;
}

export class TransferPayment {
  public amount: number;
  public step: number;
  public payment_date: Date;
  public concept: String;
  public outPayment?: boolean;
  public contract_price?: String;
  public changeAmount?: boolean = false;
  public payment_author?: boolean = false;
  public changePaymentAuthor?: boolean = false;
  public id?: String;

  public invoice_id?: number;
  public invoice_file_name?: string;
  public society_invoice?: boolean = false;
  public juridical_person?: JuridicalPerson;
  public physical_person?: PhysicalPerson;
  public DONT_GENERATE_INVOICE?: boolean = false;

  public sameMonthDate?: boolean = true;
  public laterThanDeadLine?: boolean;
  public modifiable?: boolean;
  public type?: string;

  public planned_payment_id?: number;
  public disbursements?: Array<any>;
  public file?: File;
  public enable_notifications?: boolean;

  constructor() {
    this.payment_date = new Date();
  }
}

export class Cost {
  public amount: string;
  public cost_date: Date;
  public concept: String;
  public is_disbursement: boolean = false;
  public id?: number;
  public change: boolean = false;
  public type: string;

  constructor() {
    this.cost_date = new Date();
  }
}

export function paymentDateSameMonth(paymentDate) {
  const dateTime = paymentDate;
  const parts = dateTime.split(/[- :]/);

  let month = parts[1];
  let year = parts[0];

  let currentdate = new Date();
  let cur_month = currentdate.getMonth() + 1;
  let cur_year = currentdate.getFullYear();

  if (cur_month == month && year == cur_year) {
    return true;
  } else {
    return false;
  }
}

export function paymentDateTwoDaysBeforeEnd(paymentDate) {
  let currentDate = new Date(paymentDate);
  let currentDay = currentDate.getDate();

  let lastDayDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    0
  );
  let lastWorkingDay = lastDayDate.getDate();
  let lastWeekDay = lastDayDate.getDay();
  let deadline = lastDayDate.getDate();

  if (lastWeekDay == 6) {
    // Saturday
    lastWorkingDay = lastWorkingDay - 1;
  } else if (lastWeekDay == 0) {
    // Sunday
    lastWorkingDay = lastWorkingDay - 2;
  }

  if (lastWorkingDay == currentDay || lastWorkingDay - 1 == currentDay) {
    // Set day deadline two days before last workday
    if (lastWeekDay == 1 || lastWeekDay == 2 || lastWeekDay == 0) {
      // Monday, Tuesday, Sunday
      deadline = deadline - 4;
    } else if (lastWeekDay == 3 || lastWeekDay == 4 || lastWeekDay == 5) {
      // Wednesday, Thursday, Friday
      deadline = deadline - 2;
    } else if (lastWeekDay == 6) {
      // Saturday
      deadline = deadline - 3;
    }
    return currentDay >= deadline;
  } else {
    return false;
  }
}

export class CustomPayment {
  public amount?: string;
  public concept?: string;
  public free_months?: number;
  public wants_to_sign?: boolean;
  public offer?: boolean;
  public offer_end_date?: Date;
  public planned_payment?: Number;
  constructor() {}
}

export class Invoice {
  society_invoice: boolean;
  pk: number;
  constructor() {}
}

export class BizumSignature {
  SIGNATURE_VERSION: string;
  MERCHANT_PARAMETERS: string;
  SIGNATURE: string;
}

export interface SubscriptionCardPayment {
  id: string;
  start_date: Date;
  end_date: Date;
  amount: string;
  state: boolean;
  global_payment: {
    times_execution: number;
    description: string;
  };
  subscription: Subscription;
  contract_id: number;
  schedule: string;
  card: StoredGlobalCard;
}

export interface BankDraft {
  id: string;
  iban: string;
  contract_id: number;
}

export interface Subscription {
  uuid: string;
  start_date: Date;
  end_date: Date;
  price: number;
  email: string;
  name: string;
  nif: string;
  address: string;
  concept: string;
  contract_id: string;
  generated_date: Date;
  payment_id: string;
  subscription_card_payment?: SubscriptionCardPayment;
  bank_draft?: BankDraft;
  product_name: string;
}

export interface ScheduledPaymentsExecutions {
  id: Number;
  date_execution: Date;
  amount: Number;
  result: String;
  recurrent_payment_id: String;
  status: Boolean;
  generated_id: string;
  is_executed: boolean;
}

export class StoredGlobalCard {
  id: string;
  customer: Customer;
  expiration_date: string;
  last_4_digits: string;
}
