import { Component } from '@angular/core';

@Component({
  selector: 'app-productcategory-agency-laboral',
  templateUrl: './laboral.component.html',
  styleUrl: './laboral.component.css',
})
export class ProductCategoryAgencyLaboralComponent {
  // TODO: IMPORTANT. These prices should be fetched from the backend
}
