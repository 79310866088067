import { Component } from '@angular/core';

@Component({
  selector: 'app-productcategory-agency-autonomos',
  templateUrl: './autonomos.component.html',
  styleUrl: './autonomos.component.css',
})
export class ProductCategoryAgencyAutonomosComponent {
  // TODO: IMPORTANT. These prices should be fetched from the backend
  priceByTier = [
    { name: 'starter', price: 40 },
    { name: 'pro', price: 75 },
    { name: 'premium', price: 150 },
  ];

  featuresByTier = {
    'Plataforma donde generar facturas, gestion de clientes, subir gastos, ver tus KPI y estados contables':
      ['starter', 'pro', 'premium'],

    'Asesoramiento fiscal-contable': ['starter', 'pro', 'premium'],

    'Depósito de cuentas anuales y de libros de registros contables': [
      'starter',
      'pro',
      'premium',
    ],

    'Facturas ilimitadas': ['starter', 'pro', 'premium'],

    'Declaración 347': ['starter', 'pro', 'premium'],

    'Presentación de todos los modelos de impuestos trimestrales': [
      'starter',
      'pro',
      'premium',
    ],

    'Asesoramiento mercantil': ['pro', 'premium'],

    'Asesoramiento jurídico': ['pro', 'premium'],

    'Verificación de saldos con proveedores': ['premium'],

    'Alta y mantenimiento de un dominio ': ['premium'],
  };
}
