import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Title, Meta, SafeHtml, DomSanitizer } from '@angular/platform-browser';
import { ProductCategory } from 'src/app/models/productcategory';
import { BlogRssService } from 'src/app/services/blog-rss.service';
import { ProductCategoryService } from 'src/app/services/productcategory.service';
import {
  CATEGORY_URL_TO_BLOG_CATEGORY,
  STATIC_REVIEWS_BY_CATEGORY,
} from './static_content';
import Big from 'big.js';
import { Subscription } from 'rxjs';
import { DepartmentsService } from 'src/app/services/departments.service';
import { formatPhoneNumber } from 'src/app/models/crm/crm';

const DEFAULT_CATEGORY_DESCRIPTION =
  'Contáctanos sin compromiso para obtener asistencia legal. Contamos con un equipo de abogados especialistas que pueden ayudarte y guiarte en los procesos legales.';

@Component({
  selector: 'app-productcategory',
  templateUrl: './productcategory.component.html',
  styleUrls: ['./productcategory.component.css'],
})
export class ProductCategoryComponent implements OnInit, OnDestroy {
  constructor(
    private productCategoryService: ProductCategoryService,
    private route: ActivatedRoute,
    private router: Router,
    private blogService: BlogRssService,
    private titleService: Title,
    private meta: Meta,
    private sanitizer: DomSanitizer,
    private departmentService: DepartmentsService
  ) {}

  categoryUrl: string = null;
  type: string = null;
  htmlContent: SafeHtml = null;

  category: ProductCategory = null;
  phone_number: string = '';

  articles = [];
  reviews = [];
  canvasHeight: string = null;
  private routeSubscription: Subscription;

  ngOnInit(): void {
    this.routeSubscription = this.route.params.subscribe((params) => {
      this.initializeComponent();
    });
  }

  fetchHtmlForItems() {
    if (this.category.header_html) {
      fetch(this.category.header_html)
        .then((response) => response.text())
        .then((html) => {
          this.htmlContent = this.sanitizeHtml(html);
          const canvasHeightMatch = html.match(
            /<canvas[^>]*height="(\d+)"[^>]*>/
          );
          this.canvasHeight = canvasHeightMatch ? canvasHeightMatch[1] : null;
        })
        .catch((error) => console.error('Error fetching the HTML:', error));
    }
  }

  sanitizeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy(): void {
    if (this.routeSubscription) {
      this.routeSubscription.unsubscribe();
    }
  }

  private initializeComponent(): void {
    this.categoryUrl = this.route.snapshot.params.categoryUrl;
    if (
      this.route.snapshot.url.join().split(',')[0] ===
      'servicios-para-tu-empresa'
    ) {
      this.type = 'b2b';
    } else {
      this.type = 'b2c';
    }

    this.getCategory();
    this.getArticles();
    this.getReviews();
  }

  getCategory() {
    this.productCategoryService
      .fetchProductCategory(this.categoryUrl, this.type)
      .subscribe(
        (category) => {
          this.category = category;
          this.fetchHtmlForItems();
          if (
            this.category.department &&
            this.category.department.phone_number
          ) {
            this.phone_number = this.category.department.phone_number;
          }
          this.category.title =
            this.category.title && this.category.title != ''
              ? this.category.title
              : this.category.name;

          this.titleService.setTitle(this.category.meta_title);
          this.meta.updateTag({
            name: 'description',
            content: this.category.meta_description,
          });

          this.category.description =
            this.category.description && this.category.description != ''
              ? this.category.description
              : DEFAULT_CATEGORY_DESCRIPTION;
          if (this.category.subcategories) {
            this.category.subcategories.forEach((subcategory) => {
              subcategory.products.forEach((product) => {
                if (product.product_description.price_text) {
                  const formatPrice = (price: number): string => {
                    return Big(price)
                      .toFixed(0)
                      .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                  };
                  this.category.subcategories.forEach((subcategory) => {
                    subcategory.products.forEach((product) => {
                      console.log(
                        'product',
                        product.product_description.price_text
                      );

                      if (product.product_description.price_text) {
                        product.product_description.price_text =
                          this.sanitizer.bypassSecurityTrustHtml(
                            product.product_description.price_text
                              .replace(/{{price}}/, formatPrice(product.price))
                              .replace(
                                /\{{price_without_iva}}/gi,
                                (product.price / 1.21).toLocaleString()
                              )
                              .replace(
                                '€',
                                '<span style="font-size:15px">&#8364;</span>'
                              )
                          );
                      }
                    });
                  });
                }
              });
            });
          }
          if (this.category.products) {
            this.category.products.forEach((product) => {
              if (product.product_description.price_text) {
                console.log('product', product.product_description.price_text);
                const formatPrice = (price: number): string => {
                  return Big(price)
                    .toFixed(0)
                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.');
                };
                product.product_description.price_text =
                  this.sanitizer.bypassSecurityTrustHtml(
                    product.product_description.price_text
                      .replace(/{{price}}/, formatPrice(product.price))
                      .replace(
                        /\{{price_without_iva}}/gi,
                        (product.price / 1.21).toLocaleString()
                      )
                      .replace(
                        '€',
                        '<span style="font-size:15px">&#8364;</span>'
                      )
                  );
              }
            });
          }
        },
        (error) => {
          this.router.navigate(['/pagina-no-encontrada']);
        }
      );
  }

  getArticles() {
    const blogCategory = CATEGORY_URL_TO_BLOG_CATEGORY.get(this.categoryUrl);
    this.blogService.getPostsByCategory(blogCategory).subscribe(
      (articles) => {
        this.articles = articles;
      },
      (error) => {
        this.articles = [];
      }
    );
  }

  getReviews() {
    this.reviews = STATIC_REVIEWS_BY_CATEGORY.get(this.categoryUrl) || [];
  }
}
