import { Component, Input } from '@angular/core';
import { ProductCategoryGenericProductModalComponent } from '../../generic-product-modal/generic-product-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-productcategory-agency-table',
  templateUrl: './table.component.html',
  styleUrl: './table.component.css',
})
export class ProductCategoryAgencyTableComponent {
  @Input() priceByTier = [];
  @Input() featuresByTier = {};
  @Input() tableFooterType = '';

  constructor(private dialog: MatDialog) {}

  selectedTier = 'starter';

  getKeys() {
    return Object.keys(this.featuresByTier);
  }

  getPriceByTier(tierName) {
    const tier = this.priceByTier.find((item) => item.name === tierName);
    return tier ? tier.price : null;
  }

  openGenericProductModal() {
    //TODO: ADD PRODUCT
    this.dialog.open(ProductCategoryGenericProductModalComponent, {
      panelClass: 'dialog-with-overflow',
      maxWidth: '100vw',
      maxHeight: '70vh',
      data: { product: null },
    });
  }
}
