<span class="description"
  >AUTONOMOS. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
  eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
  veniam</span
>
<app-productcategory-agency-table
  [priceByTier]="priceByTier"
  [featuresByTier]="featuresByTier"
  tableFooterType="autonomos"
></app-productcategory-agency-table>
