<div class="container">
  <span class="description"
    >LABORAL. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
    veniam</span
  >
  <table>
    <thead>
      <tr class="header-row">
        <th>Empleados</th>
        <th>Total al mes</th>
        <th>Descuento</th>
        <th>Tarifa</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1</td>
        <td>27</td>
        <td>10%</td>
        <td>28,0</td>
      </tr>
      <tr>
        <td>2</td>
        <td>32</td>
        <td>10%</td>
        <td>16,0</td>
      </tr>
      <tr>
        <td>3 a 5</td>
        <td>54</td>
        <td>10%</td>
        <td>13,5</td>
      </tr>
      <tr>
        <td>6 a 10</td>
        <td>99</td>
        <td>10%</td>
        <td>12,4</td>
      </tr>
      <tr>
        <td>11 a 15</td>
        <td>157</td>
        <td>10%</td>
        <td>12,1</td>
      </tr>
      <tr>
        <td>16 a 20</td>
        <td>189</td>
        <td>10%</td>
        <td>10,5</td>
      </tr>
      <tr>
        <td>Mas de 20</td>
        <td>9,5 por empleado</td>
        <td>10%</td>
        <td>9,5</td>
      </tr>
    </tbody>
  </table>
  <a class="me-interesa">me interesa</a>
</div>
